/*
this is the resize icon for widget edit.
this is a pseudo class
maintain dark/light mode agnostic color
*/
.react-grid-item > .react-resizable-handle::after {
  @apply border-primary !important;
}

/*
    Adds guiding gradient border towards resize hook
  */
.gridEditItem {
  border: 1px solid transparent; /* Make the border transparent by default */
  border-image-slice: 1;
}

.gridEditItem:hover {
  border-image-source: linear-gradient(
    to top left,
    var(--secondary),
    #00000000,
    #00000000
  );
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  @apply -bottom-[2px] -right-[2px] cursor-nw-resize invisible !important;
}

.react-grid-item:hover > .react-resizable-handle.react-resizable-handle-se {
  @apply visible !important;
}
/* 🚦 for the grid in reportLayout to be symmetrical to the grid in header */
.report-grid-container {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.grid-bg {
  @apply bg-background border-[#001701] rounded-sm;
  background-image: repeating-linear-gradient(#001701 0 1px, transparent 1px 100%),
    repeating-linear-gradient(90deg, #001701 0 1px, transparent 1px 100%);
  background-size: 50px 50px;
}

.grid-bg-light {
  @apply bg-white border-[#eefcefa0] rounded-sm;
  background-image: repeating-linear-gradient(#eefcefa0 0 1px, transparent 1px 100%),
    repeating-linear-gradient(90deg, #eefcefa0 0 1px, transparent 1px 100%);
  background-size: 50px 50px;
}
.react-grid-item.react-grid-placeholder {
  @apply border-2 border-black dark:border-white border-dashed rounded-md;
  background-color: transparent !important;
}

.react-grid-item img {
  pointer-events: auto !important;
  user-select: auto !important;
}
